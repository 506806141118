<template>
	<section class="search-form">
		<div class="search-form__heading">
			<div class="d-flex flex-wrap justify-content-between">
				<h1>{{ $t("AppointmentSearch.ScheduledAppt") }}</h1>
				<router-link
					:to="{
						name: 'CustomerCreateAppt',
					}"
					class="align-self-center"
				>
					{{ $t("Appointment.Register") }}
				</router-link>
			</div>
		</div>

		<hr class="h-divider" />

		<ValidationObserver ref="form" v-slot="{ handleSubmit }">
			<form @submit.prevent="handleSubmit(applyForm)">
				<div class="search-form__container">
					<!-- Appointment code -->
					<div class="form-group">
						<label for="appt-code" class="form-group__label col-form-label">{{
							$t("AppointmentSearch.ApptCode")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider v-slot="{ classes }" name="appointment_code">
								<div :class="[classes]">
									<input
										id="appt-code"
										v-model.trim="searchForm.code"
										type="text"
										class="form-control"
									/>
								</div>
							</ValidationProvider>
						</div>
					</div>
				</div>

				<div class="text-right">
					<CButton color="info" type="submit" class="btn-finish">{{
						$t("AppointmentSearch.Find")
					}}</CButton>
				</div>
			</form>
		</ValidationObserver>
	</section>
</template>

<script>
import Swal from "sweetalert2"
import { SEARCH_APPT } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("serviceAppointment")

export default {
	name: "CustomerSearchForm",

	props: {},

	data() {
		return {
			searchForm: {
				code: null,
			},
		}
	},

	computed: {},

	watch: {},

	created() {
		// this.postToIframe()
	},

	methods: {
		...mapActions({ SEARCH_APPT }),

		applyForm: async function () {
			try {
				await this.SEARCH_APPT(this.searchForm)
			} catch (error) {
				Swal.fire({
					title: `<h4>${this.$t("Button.Noti")}</h4>`,
					text: this.$t("AppointmentSearch.SearchFalse"),
					showCloseButton: true,
					confirmButtonColor: "#3A99D7",
					confirmButtonText: this.$t("Button.OK"),
					backdrop: false,
					position: "top",
				})
			}
		},

		postToIframe() {
			window.parent.postMessage("service-appt", "*")
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.search-form {
	@include form;
	@include font-lato;
	background-color: $color-white;

	&__heading {
		font-size: 16px;
		font-weight: 400;
		color: $color-black;

		h1 {
			font-size: 32px;
			font-weight: bold;
		}

		a {
			color: $color-blue;
			font-weight: bold;
			font-size: 16px;
		}

		p {
			color: $color-siam;
		}
	}
}

.form-group {
	display: flex;
	flex-wrap: wrap;

	&__label {
		flex: 0 0 100%;
		max-width: 100%;
		font-size: 16px;
		font-weight: 700;
		color: $color-black-pearl;

		@media (min-width: $xxs) {
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
		}
	}

	&__input {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xxs) {
			flex: 0 0 66.66667%;
			max-width: 66.66667%;
		}
	}

	.contact {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: min-content 1fr;
		border: 1px solid $color-hawkes-blue;
		border-radius: 0.25rem;
		height: 38px;
		position: relative;

		&:focus-within {
			@include input-focus;
		}

		/deep/ .multiselect {
			min-height: 32px;
			margin-top: 2px;
			margin-left: 2px;

			&__select {
				height: 33px;
				top: 0px;
			}

			&__tags {
				border: none;
				padding-top: 5px;
				width: max-content;
				height: 32px;
				min-height: 32px;
				border-radius: 100px;
				background: $color-black-90;
			}

			&__single {
				background: rgba(38, 34, 48, 0);
			}

			&__content-wrapper {
				width: auto;
			}

			.option__desc {
				position: relative;
				top: 2px;
			}
		}

		input {
			// padding-left: 110px;
			border: none;
			box-shadow: none;
			height: 36px;
		}

		.error-mess {
			position: absolute;
			left: 0;
		}
	}
}

.form-group.required > label::after {
	content: "*";
	color: $color-red;
	margin-left: 2px;
}

input,
textarea {
	font-size: 16px;
	color: #35495e;

	&:focus {
		@include input-focus;
	}
}

/deep/ .multiselect {
	min-height: 35px;

	&__content-wrapper {
		@include scrollbar;
	}

	&:hover {
		cursor: pointer;
	}

	&__single,
	&__option {
		display: flex;
		gap: 10px;
	}

	&__tags {
		padding-top: 8px;
		height: 38px;
		min-height: 38px;
		border: 1px solid $color-hawkes-blue;
	}
	&__select {
		height: 38px;
	}

	&__input:focus {
		border: none;
		box-shadow: none;
	}

	.option {
		&__image {
			width: 20px;
			height: 20px;
			object-fit: cover;
		}

		&__desc {
			align-self: center;
		}
	}
}

.text-right {
	.btn + .btn {
		margin-left: 7px;
	}
	.btn {
		font-weight: bold;
	}
}

.btn-pink {
	@include btn-pink;
}
.btn-ghost-pink {
	@include btn-ghost-pink;
}

.btn-finish {
	padding: 14px 32px;
	font-weight: bold;
	background-color: $color-blue;
	border-color: $color-blue;
}

.search-modal {
	/deep/ .modal-header {
		grid-template-columns: repeat(3, 1fr);

		.modal-title {
			grid-column: 2 / 3;
			text-align: center;
		}
	}

	/deep/ .modal-body {
		text-align: center;
	}
	/deep/ .modal-footer {
		justify-content: center;
	}
}
</style>
